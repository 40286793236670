import { Container } from '@mui/material';
import React from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import styles from './app.module.sass';
import './index.css';
import { TanstackQueryClientProvider } from './provider/react-query';
import { router } from './routes';
import { FilterProvider } from './store/filter-context';

export const App = () => {
  return (
    <React.StrictMode>
      <TanstackQueryClientProvider>
        <FilterProvider>
          <Container
            component='main'
            sx={{
              '::-webkit-scrollbar': {
                width: '100px',
              },
            }}
            className={styles.container}
            maxWidth='xs'
          >
            <ToastContainer />
            <RouterProvider router={router} />
          </Container>
        </FilterProvider>
      </TanstackQueryClientProvider>
    </React.StrictMode>
  );
};

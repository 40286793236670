import { useQuery } from '@tanstack/react-query';
import { MethodsService } from 'shared/services/methods.service';
import { TApplications } from 'shared/services/types/application.types';

export const useGetApplicationsExport = (data: TApplications.getApplicationsExportData) => {
  const {
    isLoading: isApplicationsExporting,
    data: applications,
    isError: isApplicationsExportError,

    refetch: refetchApplications,
    isFetched: isApplicationsFetched,
  } = useQuery({
    queryFn: () => MethodsService.getApplicationsData(data),
    queryKey: ['get applications export', data],
    enabled: false,
  });

  return {
    isApplicationsExporting,
    applications,
    isApplicationsExportError,
    refetchApplications,
    isApplicationsFetched,
  };
};

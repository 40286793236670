import { MoreHorizRounded } from '@mui/icons-material';
import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import { FC } from 'react';
import { CloseIcon } from 'shared/assets/icons';
import { TApplications } from 'shared/services/types/application.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { Status } from 'shared/ui/status';
import { TableLayout } from 'shared/ui/table-layout';
import { COLUMNS_A_ALL } from 'widgets/method/mock-data/MOCK_COL_A_ALL';

interface IProps {
  isApplicationsLoading: boolean;
  isApplicationsError: boolean;
  pageSize: number;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  applications?: TApplications.ApplicationResponse;
  matchTransaction: (id: number) => void;
  setAllOperations: React.Dispatch<React.SetStateAction<String | null>>;
  isApplicationsFetching: boolean;
}

export const MethodApplication: FC<IProps> = ({
  isApplicationsLoading,
  isApplicationsError,
  pageSize,
  setPageSize,
  page,
  setPage,
  applications,
  matchTransaction,
  setAllOperations,
  isApplicationsFetching,
}) => {
  return (
    <>
      <Box
        px={12}
        gridColumn={'1 / 3'}
        sx={{
          bgcolor: '#fff',
          borderRadius: 4,
          display: 'grid',
          gridTemplateRows: '72px auto',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexWrap: 'nowrap',
            gap: '0 24px',
          }}
        >
          <Typography variant='h3' sx={{ flexGrow: 1, fontSize: 26, lineHeight: '36px' }}>
            All Applications
          </Typography>
          <Button
            variant='outlined'
            sx={{
              '.MuiButton-startIcon': {
                width: '24px',
                minWidth: '24px',
                svg: {
                  width: '24px',
                },
              },
              p: '7px 24px',
              maxWidth: '100px',
            }}
            onClick={() => {
              setAllOperations(null);
            }}
            startIcon={<CloseIcon />}
          >
            Close
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            gridRow: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            overflow: 'hidden',
            mr: '-25px',
            pr: '25px',
          }}
        >
          <TableLayout
            pageSize={pageSize}
            setPageSize={setPageSize}
            page={page}
            setPage={setPage}
            full
            rows={!isApplicationsLoading && !isApplicationsError ? applications!.items : []}
            columns={COLUMNS_A_ALL}
            total={!isApplicationsLoading && !isApplicationsError ? applications!.total : 0}
          >
            {isApplicationsLoading && isApplicationsError ? (
              <CircularProgressCenter />
            ) : (
              <>
                {applications!.items.map((row: any) => (
                  <TableRow key={row.name}>
                    <TableCell component='th' scope='row'>
                      {row.id}
                    </TableCell>
                    <TableCell align='left'>{row.account_id}</TableCell>
                    <TableCell align='left'>{row.subagent_id}</TableCell>
                    <TableCell align='left'>{row.account_name}</TableCell>
                    <TableCell align='left'>{row.remarks}</TableCell>
                    <TableCell align='left'>{row.amount}</TableCell>
                    <TableCell align='left'>
                      <Status status={row.status} />
                    </TableCell>
                    <TableCell align='left' sx={{ cursor: 'pointer' }}>
                      <MoreHorizRounded
                        onClick={() => {
                          matchTransaction(row.id);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
                {isApplicationsFetching && <CircularProgressCenter />}
              </>
            )}
          </TableLayout>
        </Box>
      </Box>
    </>
  );
};

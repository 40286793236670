import { useEffect, useState } from 'react';
import useWindowSize from 'shared/hooks/useWindowSize';

export const usePositionCalendar = (isOpen: boolean) => {
  const [calendarRect, setCalendarRect] = useState<DOMRect>();
  const size = useWindowSize();

  const handleCalendarOpen = () => {
    const calendar = document.getElementsByClassName('react-calendar')[0];
    if (calendar) {
      const rect = calendar.getBoundingClientRect();
      setCalendarRect((prev) => (JSON.stringify(prev) !== JSON.stringify(rect) ? rect : prev));
    }
  };

  useEffect(() => {
    const observer = new MutationObserver(() => {
      handleCalendarOpen();
    });
    observer.observe(document.body, { childList: true, subtree: true });
    return () => observer.disconnect();
  }, [isOpen, size]);

  return { calendarRect };
};

import { MoreHorizRounded } from '@mui/icons-material';
import { Box, Button, TableCell, TableRow, Typography } from '@mui/material';
import { FilterContext } from 'app/store/filter-context';
import { add, format } from 'date-fns';
import { fromZonedTime } from 'date-fns-tz';
import { MethodApplication, useGetApplications } from 'features/applications';
import { FilterModal } from 'features/filter';
import { MatchTransaction } from 'features/match-transaction';
import { useChangeApplication } from 'features/match-transaction/api/useMatchTransaction';
import { MethodTransaction, useGetTransactions } from 'features/transactions';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { OpenArrowIcon, SyncIcon } from 'shared/assets/icons';
import useRefetchUsers from 'shared/hooks/useRefetchUsers';
import { TApplications } from 'shared/services/types/application.types';
import { DateRange, TFilters } from 'shared/services/types/common.types';
import { TTransaction } from 'shared/services/types/transaction.types';
import CircularProgressCenter from 'shared/ui/CircularProgressCenter';
import { Layout } from 'shared/ui/layout';
import { Status, TStatusType } from 'shared/ui/status';
import { TableLayout } from 'shared/ui/table-layout';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useGetMethods } from 'widgets/methods/api/useGetMethods';
import { COLUMNS_A } from './mock-data/MOCK_COL_A';
import { COLUMNS_T } from './mock-data/MOCK_COL_T';

export const Method = () => {
  const { updateFilter, filter, isFilterOpen, setIsFilterOpen } = useContext(FilterContext);
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const methods = useForm({ mode: 'onBlur' });
  const navigate = useNavigate();
  const { id: useParamsMethodID } = useParams();
  const { isMethodsLoading, methods: methodsData, isMethodsError } = useGetMethods();
  const [bankName, setBankName] = useState('');
  const [dateFilter, setDateFilter] = useState<DateRange>([null, null]);
  const [error, setError] = useState<any>(undefined);
  const [allOperations, setAllOperations] = useState<String | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [matchApp, setMatchApp] = useState<number>(0);

  const methodID = parseInt(useParamsMethodID!);

  const getDate = (propID: number, filter?: any) => {
    if (Array.isArray(filter)) {
      const result = filter.find((date: any, id: number) => {
        if (id === propID) {
          if (date === null || date === undefined) {
            return null;
          } else {
            return date;
          }
        } else {
          return null;
        }
      });
      if (result instanceof Date) {
        return format(
          add(result, {
            days: propID,
          }),
          'yyyy-MM-dd'
        );
      }
    } else {
      return null;
    }
  };

  useEffect(() => {
    setIsOpen(false);
    setAllOperations(null);
    const methodLabel = methodsData?.find((method) => method.id === methodID)?.name || '';
    setBankName(methodLabel);
  }, [useParamsMethodID, methodsData]);

  const [pageSizeA, setPageSizeA] = useState(20);
  const [pageA, setPageA] = useState(0);

  const [pageSizeT, setPageSizeT] = useState(20);
  const [pageT, setPageT] = useState(0);

  const [accountNumber, setAccountNumber] = useState('');
  const [status, setStatus] = useState<null | string>(null);

  const { match } = useChangeApplication();

  const onSubmitFilters = (data: TFilters) => {
    setAccountNumber(data.accountNumber);
    setStatus(data.status);
    setDateFilter(data.dateRange);
    setPageA(0);
    setPageT(0);
    setIsFilterOpen(false);
  };

  const onClose = () => {
    setAccountNumber('');
    setStatus(null);
    setDateFilter([null, null]);
    setIsFilterOpen(false);
  };

  const filterT = useMemo(() => {
    return {
      size: pageSizeT,
      page: pageT,
      order_by: 'id',
      method_id: methodID,
      ...(methodID === 18
        ? {
            subagent: accountNumber ? +accountNumber : null,
          }
        : {
            account_id: accountNumber,
          }),
      status: status ? status : undefined,
      is_desc_sort: true,
      timestamp_from: getDate(0, dateFilter),
      timestamp_to: getDate(1, dateFilter),
    } as TTransaction.GetTransaction;
  }, [onSubmitFilters, pageT, pageSizeT]);

  const filterA = useMemo(() => {
    return {
      size: pageSizeA,
      page: pageA,
      order_by: 'id',
      method_id: methodID,
      ...(methodID === 18
        ? {
            subagent: accountNumber ? +accountNumber : null,
          }
        : {
            account_id: accountNumber,
          }),
      status: status,
      is_desc_sort: true,
      timestamp_from: getDate(0, dateFilter),
      timestamp_to: getDate(1, dateFilter),
    } as TApplications.GetApplication;
  }, [onSubmitFilters, pageA, pageSizeA]);

  const matchTransaction = (id: number) => {
    setIsOpen(true);
    setMatchApp(id);
  };

  const { isTransactionsLoading, transactions, isTransactionsError, isTransactionsFetching } =
    useGetTransactions(filterT);

  const { isApplicationsLoading, applications, isApplicationsError, isApplicationsFetching } =
    useGetApplications(filterA);

  useRefetchUsers();

  useEffect(() => {
    setAccountNumber('');
    setDateFilter([null, null]);
    setStatus(null);
  }, [methodID]);

  const onSubmit = async (data: any) => {
    try {
      await match.mutateAsync({
        transaction_id: data.transaction_id,
        application_id: matchApp,
      });
      addMessageToast('Matched successfully', 'success');
      setIsOpen(false);
    } catch (error) {
      addMessageToast(error);
    }
  };

  useEffect(() => {
    updateFilter(
      methodID,
      allOperations
    )({
      timestamp_from: getDate(0, dateFilter),
      timestamp_to: getDate(1, dateFilter),
      account_id: accountNumber,
      status: status,
    });
  }, [methodID, allOperations]);

  return (
    <>
      <Layout bankName={bankName} isSingleMethod={true}>
        <FilterModal methodID={methodID} onSubmit={onSubmitFilters} onClose={onClose} />
        <Box
          gridRow='2/4'
          gridColumn='2/4'
          sx={{
            display: 'grid',
            gridTemplateColumns: 'minmax(450px,1fr) minmax(450px, 1fr)',
            gridTemplateRows: '100%',
            gap: 4,
            overflowX: 'auto',
          }}
        >
          {allOperations === null && (
            <>
              <Box
                px={12}
                sx={{
                  bgcolor: '#fff',
                  borderRadius: 4,
                  display: 'grid',
                  gridTemplateRows: '72px auto',
                  minHeight: '350px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    gap: '0 24px',
                  }}
                >
                  <Typography variant='h3' sx={{ flexGrow: 1, fontSize: 16, lineHeight: '36px' }}>
                    Applications
                  </Typography>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setAllOperations('applications');
                    }}
                    startIcon={<OpenArrowIcon />}
                    sx={{ p: '7px 26px', maxWidth: '100px' }}
                  >
                    Open
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    gridRow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    overflow: 'hidden',
                    mr: '-25px',
                    pr: '25px',
                  }}
                >
                  <TableLayout
                    pageSize={pageSizeA}
                    setPageSize={setPageSizeA}
                    page={pageA}
                    setPage={setPageA}
                    small
                    rows={!isApplicationsLoading && !isApplicationsError ? applications!.items : []}
                    columns={COLUMNS_A}
                    total={!isApplicationsLoading && !isApplicationsError ? applications!.total : 0}
                  >
                    {isApplicationsLoading ? (
                      <CircularProgressCenter />
                    ) : (
                      <>
                        {applications?.items?.map((row) => {
                          const status = row.status as TStatusType;
                          const accountId = methodID === 18 ? row.subagent_id : row.account_id;

                          return (
                            <TableRow key={row.id}>
                              <TableCell align='left'>
                                <Status status={status} />
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {row.id}
                              </TableCell>
                              <TableCell align='left'>
                                {format(fromZonedTime(row.timestamp, 'UTC'), 'dd/MM/yyyy HH:mm:SS')}
                              </TableCell>
                              <TableCell align='left'>{row.transaction_id}</TableCell>
                              <TableCell align='left'>{accountId}</TableCell>
                              <TableCell align='left'>{row.subagent_id}</TableCell>
                              <TableCell align='left'>{row.remarks}</TableCell>
                              <TableCell align='left'>{row.amount}</TableCell>
                              <TableCell align='left' sx={{ cursor: 'pointer' }}>
                                <MoreHorizRounded
                                  onClick={() => {
                                    matchTransaction(row.id);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        {isApplicationsFetching && <CircularProgressCenter />}
                      </>
                    )}
                  </TableLayout>
                </Box>
              </Box>
              <Box
                px={12}
                sx={{
                  bgcolor: '#fff',
                  borderRadius: 4,
                  display: 'grid',
                  gridTemplateRows: '72px auto',
                  minHeight: '350px',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    flexWrap: 'nowrap',
                    gap: '0 24px',
                  }}
                >
                  <Typography variant='h3' sx={{ flexGrow: 1, fontSize: 16, lineHeight: '36px' }}>
                    Transactions
                  </Typography>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setPageT(0);
                    }}
                    startIcon={<SyncIcon />}
                    sx={{ p: '7px 25px', maxWidth: '109px' }}
                  >
                    Renew
                  </Button>
                  <Button
                    variant='outlined'
                    onClick={() => {
                      setAllOperations('transactions');
                    }}
                    startIcon={<OpenArrowIcon />}
                    sx={{ p: '7px 25px', maxWidth: '100px' }}
                  >
                    Open
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    height: '100%',
                    gridRow: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    overflow: 'hidden',
                    mr: '-25px',
                    pr: '25px',
                  }}
                >
                  <TableLayout
                    pageSize={pageSizeT}
                    setPageSize={setPageSizeT}
                    page={pageT}
                    setPage={setPageT}
                    small
                    rows={!isTransactionsLoading && !isTransactionsError ? transactions?.items || [] : []}
                    columns={COLUMNS_T}
                    total={!isTransactionsLoading && !isTransactionsError ? transactions?.total || 0 : 0}
                  >
                    {isTransactionsLoading && isTransactionsError ? (
                      <CircularProgressCenter />
                    ) : (
                      <>
                        {(transactions?.items || []).map((row) => {
                          const accountId = methodID === 18 ? row.subagent_id : row.account_id;
                          const status = row?.status;
                          return (
                            <TableRow key={row.id}>
                              <TableCell align='left'>
                                <Status status={status} />
                              </TableCell>
                              <TableCell component='th' scope='row'>
                                {row.id}
                              </TableCell>
                              <TableCell align='left'>
                                {row.timestamp && format(fromZonedTime(row.timestamp, 'UTC'), 'dd/MM/yyyy HH:mm:SS')}
                              </TableCell>
                              <TableCell align='left'>{accountId}</TableCell>
                              <TableCell align='left'>{row.subagent_id}</TableCell>
                              <TableCell align='left'>{row.remarks}</TableCell>
                              <TableCell align='left'>{row.income}</TableCell>
                              <TableCell align='left'>{row.balance}</TableCell>
                              <TableCell align='left'>{row.application_id}</TableCell>
                            </TableRow>
                          );
                        })}
                        {isTransactionsFetching && <CircularProgressCenter />}
                      </>
                    )}
                  </TableLayout>
                </Box>
              </Box>
            </>
          )}
          {allOperations === 'applications' && (
            <MethodApplication
              isApplicationsLoading={isApplicationsLoading}
              isApplicationsError={isApplicationsError}
              pageSize={pageSizeA}
              setPageSize={setPageSizeA}
              page={pageA}
              setPage={setPageA}
              applications={applications}
              matchTransaction={matchTransaction}
              setAllOperations={setAllOperations}
              isApplicationsFetching={isApplicationsFetching}
            />
          )}
          {allOperations === 'transactions' && (
            <MethodTransaction
              isTransactionsLoading={isTransactionsLoading}
              isTransactionsError={isTransactionsError}
              pageSize={pageSizeT}
              setPageSize={setPageSizeT}
              page={pageT}
              setPage={setPageT}
              transactions={transactions}
              matchTransaction={matchTransaction}
              setAllOperations={setAllOperations}
              isTransactionsFetching={isTransactionsFetching}
            />
          )}
        </Box>
        <MatchTransaction onSubmit={onSubmit} isOpen={isOpen} setIsOpen={setIsOpen} error={error} />
      </Layout>
    </>
  );
};

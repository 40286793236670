import { Settings } from '@mui/icons-material';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import { Avatar, Badge, Box, Grid, List, ListItemButton, ListItemIcon, Tooltip } from '@mui/material';
import { ClientRectObject } from '@popperjs/core/lib/popper';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppsRounded, LogOut, LogoIcon, MethodsIcon } from 'shared/assets/icons';
import useUser from 'shared/hooks/useUser';
import { AuthService } from 'shared/services/auth/auth.service';
import { TMethods } from 'shared/services/types/methods.types';

interface IProps {
  methods?: TMethods.Method[];
}

export const SideNav: FC<IProps> = ({ methods }) => {
  const location = useLocation();
  const { pathname } = location;

  const { name, isAdmin } = useUser();
  const navigate = useNavigate();

  const navbarList = {
    top: [
      {
        name: 'Avatar',
        pathname: '/Avatar',
        icon: (color: string) => {
          return (
            <Badge color='success' variant='dot'>
              <Avatar alt={`${name}`} src='../../shared/assets/icons/profile.png' sx={{ width: 28, height: 28 }} />
            </Badge>
          );
        },
        onClick: () => {},
        protected: false,
      },
      {
        name: 'Dashboard',
        pathname: '/dashboard',
        icon: (color: string) => <AppsRounded />,
        onClick: () => {},
      },
      {
        name: 'Methods',
        pathname: '',
        icon: (color: string) => <MethodsIcon style={{ color }} />,
        onClick: () => navigate('/'),
      },
    ],
    bottom: [
      {
        name: 'Users',
        icon: (color: string) => <PeopleAltOutlinedIcon style={{ color }} />,
        pathname: '/users',
        onClick: () => navigate('/users'),
        protected: true,
      },
      {
        name: 'Settings',
        icon: (color: string) => <Settings style={{ color }} />,
        pathname: '/settings',

        onClick: () => {},
      },
      {
        name: 'Log out',
        icon: (color: string) => <LogOut style={{ color }} />,
        pathname: '/Log out',
        onClick: AuthService.logout,
      },
    ],
  };

  return (
    <>
      <Box
        position={'fixed'}
        height={'calc(100vh - 24px)'}
        gridColumn={1}
        gridRow='1 / 4'
        sx={{
          bgcolor: 'primary.main',
          color: '#fff',
          borderRadius: 4,
          marginRight: 2,
          overflow: 'auto',
          zIndex: '1000',
          '& .MuiDivider-root': { borderColor: '#A4B1EA' },
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'stretch',
        }}
      >
        <Grid
          container
          sx={{
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
        >
          <Grid
            item
            sx={{
              maxWidth: 1,
            }}
          >
            <Box sx={{ p: 12 }}>
              <LogoIcon />
            </Box>
          </Grid>
          <Grid item>
            <List component='div'>
              {navbarList.top.map((item) => {
                const selected = pathname === item.pathname ? '#fff' : '#8CB3F1';
                if (item.protected && !isAdmin) return null;
                return (
                  <Tooltip
                    key={item.name}
                    title={item.name}
                    arrow
                    disableInteractive
                    slotProps={{
                      popper: {
                        modifiers: [
                          {
                            name: 'offset',
                            options: {
                              offset: ({ popper }: { popper: ClientRectObject }) => {
                                return [popper.width * 0.3, 0];
                              },
                            },
                          },
                        ],
                      },
                    }}
                  >
                    <ListItemButton onClick={item.onClick}>
                      <ListItemIcon>{item.icon(selected)}</ListItemIcon>
                    </ListItemButton>
                  </Tooltip>
                );
              })}
            </List>
          </Grid>
        </Grid>
        <Grid item>
          <List component='div'>
            {navbarList.bottom.map((item) => {
              const selected = pathname === item.pathname ? '#fff' : '#8CB3F1';
              if (item.protected && !isAdmin) return null;
              return (
                <Tooltip
                  title={item.name}
                  arrow
                  disableInteractive
                  key={item.name}
                  slotProps={{
                    popper: {
                      modifiers: [
                        {
                          name: 'offset',
                          options: {
                            offset: ({ popper }: { popper: ClientRectObject }) => {
                              return [popper.width * 0.3, 0];
                            },
                          },
                        },
                      ],
                    },
                  }}
                >
                  <ListItemButton onClick={item.onClick}>
                    <ListItemIcon>{item.icon(selected)}</ListItemIcon>
                  </ListItemButton>
                </Tooltip>
              );
            })}
          </List>
        </Grid>
      </Box>
    </>
  );
};

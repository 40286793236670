import { Box, Typography } from '@mui/material';
import { QRCodeCanvas } from 'qrcode.react';

const TWO_FACTOR_KEY = '5om442vc24wwl24vmkkf22v';

const QrScan = () => {
  const qrData = 'otpauth://totp/{SERVICE_NAME}:{USasdsdER_EMAIL}?secret={SECRET}&issuer={SERVICE_NAME}';
  return (
    <>
      <Typography sx={{ width: '100%', textAlign: 'center', marginTop: '20px' }} variant='h5' maxWidth='365px'>
        Use Google Authentication app to scan this QR code
      </Typography>
      <QRCodeCanvas style={{ marginTop: '26px' }} value={qrData} size={250} />;
      <Typography
        sx={{ maxWidth: '100%', display: 'block', textAlign: 'center', marginTop: '24px' }}
        variant='body1'
        maxWidth='365px'
        fontWeight='500'
        letterSpacing='0.25px'
      >
        Or enter this code in application for authentication
      </Typography>
      <Typography
        sx={{ width: '100%', display: 'block', textAlign: 'center', marginTop: '18px', fontWeight: 700 }}
        variant='h5'
        maxWidth='365px'
      >
        {TWO_FACTOR_KEY}
      </Typography>
    </>
  );
};
export default QrScan;

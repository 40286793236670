import { Box } from '@mui/material';
import clsx from 'clsx';
import React, { PropsWithChildren } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

interface IProps extends PropsWithChildren {
  onSubmit: any;
  methods: UseFormReturn<FieldValues, any, undefined>;
  className?: string;
  id?: string;
}

export const FormWrapper: React.FC<IProps> = ({ className, methods, children, onSubmit, id }) => {
  return (
    <FormProvider {...methods}>
      <Box
        component='form'
        onSubmit={methods.handleSubmit(onSubmit)}
        sx={{ p: '30px 24px' }}
        className={clsx(className, 'form')}
        id={id}
      >
        {children}
      </Box>
    </FormProvider>
  );
};

import { useState, useEffect, useLayoutEffect } from 'react';
import { TAuth } from 'shared/services/types/auth.types';

const useUser = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}') as TAuth.Data
  const [isAdmin, setIsAdmin] = useState(user?.role === 'Администратор');
  const [name, setName] = useState('');

  useLayoutEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser !== null) {
      const parsedUser = JSON.parse(storedUser);
      setName(parsedUser.name);
    }
  }, []);

  return { isAdmin, name, user };
};
export default useUser;

import DropdownArrow from '../../../shared/assets/icons/Dropdown-arrow.svg';

export const styles = {
  width: '100%',
  '&.is-Active': {
    '.react-date-picker__wrapper': {
      borderColor: '#185AC2',
      borderWidth: '2px',
      '&:hover': {
        borderColor: '#185AC2',
      },
    },
    '.react-daterange-picker__wrapper': {
      borderColor: '#185AC2',
      borderWidth: '2px',
      '&:hover': {
        borderColor: '#185AC2',
      },
    },
    '.MuiFormLabel-root': {
      color: '#185AC2',
    },
  },
  '&.is-Full': {
    '.react-date-picker__clear-button': {
      display: 'block',
    },
    '.react-daterange-picker__clear-button': {
      display: 'block',
    },
    '.react-daterange-picker__wrapper': {
      borderColor: '#185AC2',
      borderWidth: '2px',
      '&:hover': {
        borderColor: '#185AC2',
      },
    },
  },
  '&.is-Dropdown-Open': {
    '.react-calendar__navigation__label::before': {
      transform: 'rotate(180deg)',
      top: '45%',
    },
  },
  '.custom-header': {
    display: 'none',
  },
  '.react-date-picker': {
    inset: 'calc(100% - 1px) !important auto auto 0px',
    '&--open': {
      '+ .custom-header': {
        display: 'flex',
      },
    },
    '&__inputGroup': {
      height: '24px',
      p: '0',
      marginRight: 'auto',
      maxWidth: '100px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&[value=""]': {
        color: '#90939B',
      },
      '&__input': {
        '&:invalid': {
          background: 'none',
        },
      },
    },
    '&__wrapper': {
      p: '15px 16px',
      border: '1px solid #BDC1D2',
      borderRadius: '4px',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      maxWidth: '100%',
      color: '#44464F',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        borderColor: '#1B1B1F',
      },
      '&:focus-within': {
        borderColor: '#185AC2',
        borderWidth: '2px',
      },
    },
    '&__calendar': {
      zIndex: '3',
      '&-button': {
        p: '0',
      },
    },
    '&__range-divider': {
      margin: '0 8px 0 0',
      width: '10px',
    },
    '&__button': {
      '&:enabled:focus': {
        '.react-date-picker__button__icon': {
          stroke: 'black',
        },
      },
      '&:enabled:focus:hover': {
        '.react-date-picker__button__icon': {
          stroke: '#185AC2',
        },
      },
    },
    '&__calendar-button': {
      '&:enabled:focus': {
        '.react-date-picker__button__icon': {
          stroke: 'black',
        },
      },
      '&:enabled:focus:hover': {
        '.react-date-picker__button__icon': {
          stroke: '#185AC2',
        },
      },
    },
    '&__clear-button': {
      display: 'none',
      padding: '2px 6px 3px',
    },
  },
  '.react-daterange-picker': {
    inset: 'calc(100% - 1px) !important auto auto 0px',
    '&--open': {
      '+ .custom-header': {
        display: 'flex',
      },
    },
    '&__inputGroup': {
      height: '24px',
      p: '0',
      maxWidth: '105px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '&__input': {
        '&:invalid': {
          background: 'none',
        },
      },
      '&[value=""]': {
        color: '#90939B',
      },
    },
    '&__wrapper': {
      p: '15px 16px',
      border: '1px solid #BDC1D2',
      borderRadius: '4px',
      fontSize: '16px',
      lineHeight: '24px',
      letterSpacing: '-0.5px',
      color: '#44464F',
      '&:hover': {
        borderColor: '#1B1B1F',
      },
      '&:focus-within': {
        borderColor: '#185AC2',
        borderWidth: '2px',
      },
    },
    '&__calendar': {
      zIndex: '3',
      width: '100%',
      '&-button': {
        p: '0',
        marginLeft: 'auto',
        '&:hover': {
          '& svg': {
            '& path': {
              fill: '#185AC2',
            },
          },
        },
        '&:enabled:focus': {
          '.react-daterange-picker__button__icon': {
            stroke: 'black',
          },
        },
        '&:enabled:focus:hover': {
          '.react-daterange-picker__button__icon': {
            stroke: '#185AC2',
          },
        },
      },
    },
    '&__range-divider': {
      margin: '0 8px 0 0',
      width: '10px',
    },
    '&__button': {
      '&:enabled:focus': {
        '.react-daterange-picker__button__icon': {
          stroke: 'black',
        },
      },
      '&:enabled:focus:hover': {
        '.react-daterange-picker__button__icon': {
          stroke: '#185AC2',
        },
      },
    },
    '&__clear-button': {
      display: 'none',
      padding: '2px 6px 3px',
      '&:enabled:focus': {
        '.react-daterange-picker__button__icon': {
          stroke: 'black',
        },
      },
    },
  },
  '.react-calendar': {
    fontFamily: 'Roboto',
    width: 'auto',
    '& abbr': {
      display: 'block',
      position: 'relative',
      zIndex: '3',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
      color: '#44464F',
    },
    '&__navigation': {
      borderTop: '1px solid #BDC1D2',
      borderBottom: '1px solid #BDC1D2',
      marginTop: '40px',
      marginBottom: '00px',
      height: '52px',
      '&__label': {
        order: '-1',
        padding: '14px 16px',
        marginRight: 'auto',
        minWidth: '155px',
        maxWidth: 'auto',
        position: 'relative',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
        letterSpacing: '0.1px',
        flexGrow: '0 !important',
        '&::before': {
          content: `''`,
          width: '10px',
          height: '5px',
          position: 'absolute',
          top: '50%',
          right: '16px',
          transform: 'translateY(-50%)',
          zIndex: '1',
          backgroundImage: `url(${DropdownArrow})`,
          backgroundRepeat: 'no-repeat',
        },
        '&__labelText': {
          fontFamily: 'Roboto, sans-serif',
          mr: '20px',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '0.1px',
        },
      },
    },

    '&__viewContainer': {
      m: '0 auto',
      paddingBottom: '8px',
    },

    '&__tile': {
      margin: '1px 0',
      position: 'relative',
      fontFamily: 'Roboto, sans-serif',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
      fontSize: '14px',
      padding: '5px',
      '& abbr': {
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '20px',
        letterSpacing: '0.25px',
      },
      '&:disabled': {
        background: 'none',
        '& abbr': {
          color: '#d3d3d3',
        },
      },
      '&:enabled': {
        '&:hover': {
          background: 'none',
          color: '#fff',

          '& abbr': {
            color: '#fff',
          },

          '&:after': {
            content: `''`,
            display: 'block',
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            transform: 'translate(-50%, -50%)',
            background: '#6d8fe0',
          },
        },
      },
      '&--hover': {
        background: 'transparent !important',
        position: 'relative',
        '&:after': {
          content: `''`,
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '30px',
          transform: 'translate(-50%, -50%)',
          background: '#D9E2FF',
        },
        '&Start': {
          '&:before': {
            content: `''`,
            display: 'block',
            position: 'absolute',
            top: '50%',
            right: '0%',
            background: '#D9E2FF',
            transform: 'translate(0, -50%)',
            height: '60%',
            minHeight: '30px',
            width: '50%',
            zIndex: '0',
          },
        },
        '&BothEnds': {
          '&:before': {},
          '&:after': {},
        },
        '&End': {
          '&:before': {
            content: `''`,
            display: 'block',
            position: 'absolute',
            top: '50%',
            left: '0%',
            background: '#D9E2FF',
            transform: 'translate(0, -50%)',
            height: '60%',
            minHeight: '30px',
            width: '50%',
            zIndex: '0',
          },
        },
      },
      '&--now': {
        background: 'none',
        '&.react-calendar__month-view__days__day.react-calendar__tile--range': {
          '&:before': {
            borderRadius: '0px',
            maxWidth: '100%',
          },
        },
        '&:before': {
          content: `''`,
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          border: '1px solid #d3d3d3',
          height: '60%',
          minHeight: '30px',
          width: '100%',
          borderRadius: '15px',
        },
      },
      '&--hasActive': {
        background: 'none',
        '&:before': {
          content: `''`,
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          background: '#D9E2FF',
          height: '60%',
          minHeight: '30px',
          width: '100%',
          zIndex: '1',
          borderRadius: '15px',
        },
      },
      '&--active': {
        background: 'none',
        '&:enabled': {
          '&:hover': {
            background: 'none',
          },
          '&:focus': {
            background: 'none',
          },
        },
      },
      '&--range': {
        // диапазон
        background: 'transparent',
        position: 'relative',
        p: '0',
        color: '#44464d',
        '&:before': {
          content: `''`,
          display: 'block',
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '100%',
          height: '60%',
          minHeight: '30px',
          transform: 'translate(-50%, -50%)',
          background: '#D9E2FF',
        },
        '& abbr': {
          color: '#44464d',
        },
        '&Start': {
          // первый в диапазоне
          '& abbr': {
            color: '#fff',
          },
          '&.react-calendar__tile--rangeEnd': {
            borderRadius: '50%',
          },
          '&.react-calendar__tile--range': {
            position: 'relative',
            '&:before': {
              content: `''`,
              display: 'block',
              position: 'absolute',
              top: '50%',
              right: '0',
              left: 'auto',
              width: '50%',
              borderRadius: '0',
              zIndex: '0',
              transform: 'translate(0, -50%)',
              background: '#D9E2FF',
            },
            '&:after': {
              content: `''`,
              display: 'block',
              position: 'absolute',
              top: '50%',
              left: '50%',
              width: '30px',
              height: '30px',
              transform: 'translate(-50%, -50%)',
              borderRadius: '50%',
              background: '#185AC2',
            },
          },
        },
        '&BothEnds': {
          '&:before': {
            display: 'none !important',
          },
        },
        '&End': {
          // последний в диапазоне
          '& abbr': {
            color: '#fff',
          },
          '&.react-calendar__tile--range': {
            position: 'relative',
            '&:after': {
              content: `''`,
              display: 'block',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '60%',
              minHeight: '30px',
              borderRadius: '50%',
              background: '#185AC2',
            },
          },
        },
      },
    },
    '&__century-view': {
      '&__decades__decade': {
        zIndex: '5',
        '&.react-calendar__tile': {
          py: '19px',
          '&--hover': {
            zIndex: '5',
            '&:after': {
              zIndex: '-1',
              height: '60%',
              minHeight: '30px',
            },
          },
          '&:hover': {
            zIndex: '5',
            '&::after': {
              height: '60%',
              width: '100%',
              zIndex: '-1',
              borderRadius: '15px',
            },
            '&:before': {
              zIndex: '-1',
            },
          },
          '&--hasActive': {
            '&:before': {
              zIndex: '-1',
            },
          },
          '&--range': {
            '&:last-child': {
              '&::before': {
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
            '&:nth-child(3n-2)': {
              '&::before': {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
              },
            },
            '&:nth-child(3n)': {
              '&::before': {
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
            '&:hover': {
              '&::after': {
                height: '60%',
                width: '100%',
                borderRadius: '15px',
              },
            },
            '&:after': {
              zIndex: '-1',
            },
            '&:before': {
              zIndex: '-1',
            },
            '&Start': {
              color: '#fff',
              borderRadius: '0',
              '&:after': {
                width: '100%',
                height: '60%',
                borderRadius: '15px',
              },
            },
            '&End': {
              color: '#fff',
              borderRadius: '0',
              '&:after': {
                borderRadius: '15px',
              },
              '&:before': {
                width: '50%',
                left: '0',
                transform: 'translate(0, -50%)',
                borderRadius: '0',
              },
            },
          },
        },
      },
    },
    '&__decade-view': {
      '&__years__year': {
        zIndex: '5',
        '&.react-calendar__tile': {
          py: '19px',
          '&--hover': {
            zIndex: '5',
            '&:after': {
              zIndex: '-1',
              height: '60%',
              minHeight: '30px',
            },
            '&:before': {
              zIndex: '-1',
            },
          },
          '&:hover': {
            zIndex: '5',
            '&::after': {
              height: '60%',
              width: '100%',
              zIndex: '-1',
              borderRadius: '15px',
            },
          },

          '&--hasActive': {
            '&:before': {
              zIndex: '-1',
            },
          },
          '&--range': {
            '&:last-child': {
              '&::before': {
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
            '&:hover': {
              '&::after': {
                zIndex: '-1',
                borderRadius: '15px',
              },
            },
            '&::after': {
              zIndex: '-1',
              borderRadius: '15px',
            },
            '&::before': {
              zIndex: '-1',
            },
            '&:nth-child(3n-2)': {
              '&:before': {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
              },
              '&.react-calendar__tile--hover:before': {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
              },
            },
            '&:nth-child(3n)': {
              '&:before': {
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
              '&.react-calendar__tile--hover:before': {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
              },
            },
            '&Start': {
              color: '#fff',
              borderRadius: '0',
              '&:after': {
                width: '100%',
                height: '60%',
              },
            },
            '&End': {
              color: '#fff',
              borderRadius: '0',
              '&:before': {
                width: '50%',
                left: '0',
                transform: 'translate(0, -50%)',
                borderRadius: '0',
              },
            },
          },
        },
      },
    },
    '&__year-view': {
      '&__months__month': {
        '&.react-calendar__tile': {
          py: '19px',
          '&--hover': {
            '&:after': {
              borderRadius: '0',
              height: '60%',
              width: '100%',
              minHeight: '30px',
            },
          },
          '&:hover': {
            zIndex: '5',
            '&::after': {
              height: '60%',
              width: '100%',
              zIndex: '1',
              borderRadius: '15px',
            },
          },
          '&--now': {},
          '&--active': {
            '&:hover': {
              background: 'none',
            },
          },

          '&--range': {
            '&:hover': {
              '&::after': {
                borderRadius: '15px',
              },
            },
            '&:nth-child(3n-2)': {
              '&::before': {
                borderTopLeftRadius: '15px',
                borderBottomLeftRadius: '15px',
              },
            },
            '&:nth-child(3n)': {
              '&::before': {
                borderTopRightRadius: '15px',
                borderBottomRightRadius: '15px',
              },
            },
            '&Start': {
              borderRadius: '0',
              '&:after': {
                width: '100%',
                height: '60%',
                borderRadius: '15px',
                zIndex: '1',
              },
            },
            '&End': {
              borderRadius: '0',
              '&:before': {
                width: '50%',
                left: '0',
                borderRadius: '0',
                transform: 'translate(0, -50%)',
              },
              '&:after': {
                borderRadius: '15px',
                zIndex: '1',
              },
            },
          },
        },
      },
    },
    '&__month-view': {
      '&__weekdays': {
        '&__weekday': {
          height: '32px',
          color: '#44464F',
          fontFamily: 'Roboto',
          textAlign: 'center',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '16px',
          letterSpacing: '0.4px',
          textDecoration: 'none',
          '& abbr': {
            color: '#44464F',
            textAlign: 'center',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '16px',
            letterSpacing: '0.4px',
            textDecoration: 'none',
          },
        },
      },
      '&__days__day': {
        height: '32px',
        color: '#44464F',
        textAlign: 'center',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '16px',
        letterSpacing: '0.4px',
        textDecoration: 'none',
        '&--weekend': {
          '&:nth-child(7n-6)': {
            '&:before': {
              borderTopLeftRadius: '50%',
              borderBottomLeftRadius: '50%',
              width: '89%',
              right: '0',
              left: 'auto',
              transform: 'translate(0, -50%)',
            },
          },
          '&:nth-child(7n)': {
            '&:before': {
              borderTopRightRadius: '50%',
              borderBottomRightRadius: '50%',
              width: '89%',
              left: '50%',
              right: 'auto',
              transform: 'translate(-50%, -50%)',
            },
          },
        },
        '&.react-calendar__tile': {
          '&--now': {
            '&:before': {
              maxWidth: '30px',
            },
          },
          '&--range': {
            '&End': {
              '&:before': {
                width: '50%',
                left: '0',
                borderRadius: '0',
                transform: 'translate(0, -50%)',
              },
            },
            '&:after': {
              width: '30px',
              height: '30px',
            },
          },
        },
        '& abbr': {
          textAlign: 'center',
          letterSpacing: '0.4px',
          textDecoration: 'none',
          position: 'relative',
          zIndex: '3',
        },
        '&--neighboringMonth': {
          '& abbr': {
            color: '#B4B5B9',
          },
        },
      },
    },
  },
};

import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import { FilterContext } from 'app/store/filter-context';
import { AddAccountModal } from 'features/add-account';
import { AddMethodModal } from 'features/add-method';
import { useGetApplicationsExport } from 'features/applications/api/useGetApplicationExport';
import { useGetTransactionsExport } from 'features/transactions/api/useGetTransactionExport';
import { WithdrawalModal } from 'features/withdrawal';
import { FC, PropsWithChildren, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DownloadIcon, ExportIcon, FilterAlt } from 'shared/assets/icons';
import useUser from 'shared/hooks/useUser';
import { downloadExportFile } from 'shared/utils/downloadExportFile';

interface IProps extends PropsWithChildren {
  bankName?: string | undefined;
  isSingleMethod?: boolean;
}

export const Header: FC<IProps> = ({ bankName, isSingleMethod }) => {
  const { filter, setIsFilterOpen } = useContext(FilterContext);
  const { currentMethod, mode } = filter;
  const filterParams = {
    methodID: currentMethod,
    ...filter[currentMethod],
  };

  const [isOpenWithdrawal, setIsOpenWithdrawal] = useState(false);
  const [isOpenAddMethod, setIsOpenAddMethod] = useState(false);
  const [modalAccountAdd, setModalAccountAdd] = useState(false);

  const { id: useParamsMethodID } = useParams();
  const methodID = parseInt(useParamsMethodID!);

  const { isAdmin } = useUser();
  const downloadTransactionsTrigger = useRef(false);
  const downloadApplicationsDataTrigger = useRef(false);
  const { applications, refetchApplications } = useGetApplicationsExport(filterParams);
  const { transactions, refetchTransactions } = useGetTransactionsExport(filterParams);

  const handleDownloadData = () => {
    if (mode !== 'transactions') {
      refetchApplications();
      downloadApplicationsDataTrigger.current = true;
    }
    if (mode !== 'applications') {
      refetchTransactions();
      downloadTransactionsTrigger.current = true;
    }
  };
  useEffect(() => {
    if (applications && downloadApplicationsDataTrigger.current) {
      downloadExportFile(applications, 'applications');

      downloadApplicationsDataTrigger.current = false;
    }
  }, [applications]);
  useEffect(() => {
    if (transactions && downloadTransactionsTrigger.current) {
      downloadExportFile(transactions, 'transactions');
      downloadTransactionsTrigger.current = false;
    }
  }, [transactions]);

  return (
    <Box
      gridColumn='2/4'
      gridRow='1'
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        gap: 12,
        py: 10,
        px: 12,
        borderRadius: 4,
        bgcolor: '#fff',
      }}
    >
      <Typography variant='h3' color='primary' sx={{ flexGrow: 1, fontSize: 28, lineHeight: '36px' }}>
        {bankName ? 'Methods: ' + bankName : 'Methods'}
      </Typography>
      {isAdmin && (
        <Button
          variant='contained'
          sx={{ minWidth: 188, py: 5 }}
          startIcon={<Add />}
          onClick={() => {
            setIsOpenAddMethod(true);
          }}
        >
          Add method
        </Button>
      )}
      {methodID ? (
        <Button
          variant='outlined'
          onClick={() => {
            setModalAccountAdd(true);
          }}
          sx={{ p: '10px 24px', maxWidth: 162 }}
          startIcon={<Add />}
        >
          Add account
        </Button>
      ) : (
        ''
      )}

      {isSingleMethod && (
        <>
          <Button
            variant='outlined'
            sx={{ minWidth: 188, py: 5 }}
            startIcon={<DownloadIcon />}
            onClick={() => {
              setIsOpenWithdrawal(true);
            }}
          >
            Withdrawal
          </Button>
          <Button
            variant='outlined'
            sx={{ minWidth: 188, py: 5, position: 'relative' }}
            startIcon={<ExportIcon />}
            onClick={handleDownloadData}
          >
            {filter?.[currentMethod] && Object.values(filter?.[currentMethod])?.length !== 0 && (
              <Box
                sx={{
                  width: 6,
                  height: 6,
                  borderRadius: '50%',
                  backgroundColor: 'red',
                  position: 'absolute',
                  top: 12,
                  right: 19,
                }}
              />
            )}
            Download data
          </Button>
        </>
      )}
      {methodID ? (
        <Button
          variant='outlined'
          onClick={() => {
            setIsFilterOpen(true);
          }}
          sx={{ p: '10px 24px', maxWidth: 162 }}
          startIcon={<FilterAlt />}
        >
          Filter
        </Button>
      ) : (
        ''
      )}

      <AddMethodModal isOpen={isOpenAddMethod} setIsOpen={setIsOpenAddMethod}></AddMethodModal>
      <WithdrawalModal isOpen={isOpenWithdrawal} setIsOpen={setIsOpenWithdrawal}></WithdrawalModal>
      <AddAccountModal isOpen={modalAccountAdd} setIsOpen={setModalAccountAdd} methodID={methodID} />
    </Box>
  );
};

import { Box, MenuItem } from '@mui/material';
import { FC } from 'react';
import { Control, Controller, FieldErrors, FieldValues, RegisterOptions } from 'react-hook-form';
import { FilterItem } from '../filter-item';

interface IProps {
  name: string;
  control: Control<FieldValues>;
  label?: string;
  placeholder?: string;
  error?: FieldErrors<FieldValues> | string;
  required?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  options: { label: string | number; value: string | number }[];
  rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  helperText?: string;
}
export const Select: FC<IProps> = ({
  control,
  name,
  rules,
  error,
  label,
  placeholder,
  options,
  required,
  disabled,
  isLoading,
  helperText,
}) => {
  const errorMessage = typeof error === 'string' ? error : (error?.[name]?.message as string);
  return (
    <Box style={{ position: 'relative' }}>
      <Controller
        control={control}
        rules={rules}
        name={name}
        render={({ field: { onChange, onBlur, value } }) => (
          <FilterItem
            control={control}
            rules={rules}
            name={name}
            onBlur={onBlur}
            labelInner={label + (required ? ' *' : '')}
            placeholder={placeholder}
            value={value}
            setValue={onChange}
            htmlId={name}
            disabled={disabled}
            isLoading={isLoading}
          >
            {options.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </FilterItem>
        )}
      />
      <Box
        style={{
          marginLeft: '16px',
          fontSize: helperText ? 13 : 12,
          color: helperText ? '#49454F' : '#b20000',
          textAlign: 'start',
          fontWeight: helperText ? 600 : 'normal',
        }}
      >
        {(helperText && <span>{helperText}</span>) || errorMessage}
      </Box>
    </Box>
  );
};

import React, { FC } from 'react';
import { FieldValues, UseFormReturn } from 'react-hook-form';
import { AccountForm } from './AccountForm';
import { UserForm } from './UserForm';
import { TUsers } from 'shared/services/types/users.types';

interface IProps {
  step: number;
  setStep: any;
  methodAccount: any;
  methodsUser: any;
  onSubmitUser: any;
  onSubmitAccount: any;
  setBirthday: React.Dispatch<React.SetStateAction<string | undefined>>;
  birthday: string | undefined;
  methodsOptions: { label: string; value: string | number }[];
  onClose: () => void;
  userData?: TUsers.UserData;
}
export const UserSteps: FC<IProps> = ({
  step,
  setStep,
  methodAccount,
  methodsUser,
  onSubmitAccount,
  onSubmitUser,
  birthday,
  methodsOptions,
  onClose,
  setBirthday,
  userData,
}) => {
  switch (step) {
    case 2:
      return (
        <AccountForm
          methods={methodAccount}
          methodsOptions={methodsOptions}
          onSubmit={onSubmitAccount}
          setStep={setStep}
        />
      );

    default:
      return (
        <UserForm
          birthday={birthday}
          methods={methodsUser}
          methodsOptions={methodsOptions}
          onClose={onClose}
          onSubmit={onSubmitUser}
          setBirthday={setBirthday}
          userData={userData}
          setStep={setStep}
        />
      );
  }
};

export interface IStep {
  onSubmit: any;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  methods: UseFormReturn<FieldValues, any, undefined>;
  methodsOptions: { label: string; value: string | number }[];
}

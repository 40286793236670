import { useQuery } from '@tanstack/react-query';
import { MethodsService } from 'shared/services/methods.service';
import { TTransaction } from 'shared/services/types/transaction.types';

export const useGetTransactionsExport = (data: TTransaction.getTransactionsExportData) => {
  const {
    isLoading: isTransactionsExportLoading,
    data: transactions,
    isError: isTransactionsExportError,
    isFetching: isTransactionsExportFetching,
    refetch: refetchTransactions,
    isFetched: isTransactionsFetched,
  } = useQuery({
    queryFn: () => MethodsService.getTransactionsData(data),
    queryKey: ['get transactions export', data],
    enabled: false,
  });

  return {
    isTransactionsExportLoading,
    transactions,
    isTransactionsExportError,
    isTransactionsExportFetching,
    refetchTransactions,
    isTransactionsFetched,
  };
};

import { useMutation } from '@tanstack/react-query';
import { TAuth } from 'shared/services/types/auth.types';
import { TwoFactorAuthService } from 'shared/services/two-factor-auth/twoFactorAuth.service';

export const useCreateTwoFactor = () => {
  const create = useMutation({
    mutationFn: (data: TAuth.TwoFactorCreate) => TwoFactorAuthService.create(data),
    mutationKey: ['create two factor'],
  });

  return { create };
};

import { Add } from '@mui/icons-material';
import { Box, Button, Grid } from '@mui/material';
import React, { FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import isFormChanged from 'shared/helpers/isFormChanged';
import { TMethods } from 'shared/services/types/methods.types';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { Input } from 'shared/ui/input';
import { ModalTemplate } from 'shared/ui/modal-template';
import { addMessageToast } from 'shared/utils/addMessageToast';
import { useCreateMethod } from '../api/useCreateMethod';

interface IProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AddMethodModal: FC<IProps> = ({ setIsOpen, isOpen }) => {
  const methods = useForm({ mode: 'onBlur' });
  const { create } = useCreateMethod();
  const { reset, control } = methods;
  const formErrors = methods.formState.errors;
  const isConfirmationNeeded = isFormChanged(methods.watch());

  const onSubmit: SubmitHandler<TMethods.CreateMethod> = async (data) => {
    try {
      await create.mutateAsync(data);
      addMessageToast('Method successfully added', 'success');
    } catch (error) {
      addMessageToast(error);
    }
  };

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        setIsOpen(false);
      }}
      isOpen={isOpen}
      titleText={'Add payment method'}
      hideBackdrop={false}
      reset={reset}
    >
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item>
              <Input
                name='name'
                label='Method name *'
                placeholder='Enter method name'
                rules={{ required: 'Please, enter method name' }}
                control={control}
                error={formErrors.name?.message?.toString()}
              />
            </Grid>
            <Grid item>
              <Input
                name='min_value'
                label='Minimal sum *'
                placeholder='Enter minimal sum'
                rules={{ required: 'Please, enter minimal sum' }}
                control={control}
                error={formErrors.min_value?.message?.toString()}
              />
            </Grid>
            <Grid item>
              <Input
                name='decline_time'
                label='Decline time *'
                placeholder='Enter decline time'
                rules={{ required: 'Please, enter decline time' }}
                control={control}
                error={formErrors.decline_time?.message?.toString()}
              />
            </Grid>
            <Grid item>
              <Input
                name='currency'
                label='Currency *'
                placeholder='Enter currency for payment method'
                rules={{ required: 'Please, enter currency' }}
                control={control}
                error={formErrors.currency?.message?.toString()}
              />
            </Grid>
          </Grid>
        </Box>
        <Button
          type='submit'
          fullWidth
          variant='contained'
          sx={{ borderRadius: 50, textTransform: 'none', height: 40, marginTop: 15, marginBottom: 3 }}
          startIcon={<Add />}
        >
          Create
        </Button>
      </FormWrapper>
    </ModalTemplate>
  );
};

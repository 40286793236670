//@ts-nocheck
import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
  palette: {
    primary: { main: '#185AC2' },
    secondary: { main: '#001944' },
    success: { main: '#1EBD8D', dark: '#0F8E68' },
    error: { main: '#BA1A1A' },
    text: {
      primary: '#1B1B1F',
      secondary: '#90939B',
    },
    background: {
      default: '#E2EAF7',
      paper: '#fff',
    },
    divider: '#E9ECF5',
  },
  typography: {
    fontFamily: ['Roboto'].join(','),
  },
  spacing: 2,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 28,
          textTransform: 'none',
          fontWeight: '400',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          padding: 12,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          width: 'auto',
          margin: '0 auto',
          minWidth: '0px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        secondary: {
          color: '#A4B1EA',
        },
      },
    },
    MuiTooltip: {
      defaultProps: {
        arrow: {},
      },
      styleOverrides: {
        popperArrow: {},
        tooltipArrow: {
          background: 'rgba(0, 0, 0, 0.78);',
          fontFamily: 'Roboto',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '20px',
          letterSpacing: '0.25px',
          padding: '8px 24px',
          borderRadius: '8px',
        },
        arrow: {
          color: 'rgba(0, 0, 0, 0.78);',
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '.MuiTableCell-root': {
            backgroundColor: '#D9E2FF',
            color: '#90939B',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #BDC1D2',
          paddingTop: '3px',
          paddingBottom: '3px',
          height: '48px',
          color: '#44464F',
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          minWidth: 24,
          minHeight: 24,
          maxHeight: 24,
          borderRadius: 2,
          margin: '0 .1vw',
          color: '#44464F',
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: {
          minWidth: 10,
          height: 10,
          borderRadius: '50%',
          transform: 'scale(1) translate(2px, -2px)',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 12,
          lineHeight: '16px',
          color: '#44464F',
          transform: 'scale(1) translate(14px, -9px)',
          background: '#fff',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#BA1A1A',
          bottom: '-28px',
          left: 'calc(50% - 40px)',
        },
      },
    },
    MuiPickersPopper: {
      styleOverrides: {
        root: {
          '.MuiPickersPopper-paper': {
            background: '#F3F6FE',
            borderRadius: '16px',
            position: 'absolute',
            top: '27px',
          },
        },
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: '#1D1B20',
          '.MuiDayCalendar-weekDayLabel': {
            width: '40px',
            minWidth: '40px',
            height: '40px',
            minHeight: '40px',
            margin: '4px',
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            color: '#1D1B20',
          },
          '.MuiDateCalendar-root': {
            maxWidth: '360px',
            width: '360px',
            maxHeight: '404px',
            height: 'auto',
          },
          '.MuiDateCalendar-viewTransitionContainer': {
            padding: '0 12px 4px 12px',
          },
          '.MuiYearCalendar-root': {
            width: '100%',
          },
          '.MuiMonthCalendar-root': {
            width: '100%',
            gap: '20px 0px',
          },
          '.MuiPickersMonth-monthButton': {
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            fontWeight: '500',
          },
          '.MuiPickersDay-dayOutsideMonth': {
            color: '#49454F',
          },
          '.MuiDayCalendar-monthContainer': {
            position: 'relative',
          },
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          flexDirection: 'row-reverse',
          justifyContent: 'flex-start',
          gap: '8px',
          padding: '8px 12px',
          '.MuiButtonBase-root': {
            fontSize: '14px',
            lineHeight: '20px',
            fontWeight: '500',
            letterSpacing: '0.1px',
            padding: '10px 12px',
            minWidth: '43px',
            margin: '0',
          },
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          width: '40px',
          minWidth: '40px',
          height: '40px',
          minHeight: '40px',
          margin: '4px',
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.5px',
          color: '#1D1B20',
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        weekContainer: {
          margin: 0,
        },
      },
    },
  },
});

import { axiosInstance } from 'shared/api';
import { TAuth } from '../types/auth.types';
import { toFormData } from 'axios';
import { getUserUrl } from 'shared/api/config/api.config';

export const TwoFactorAuthService = {
  async create(data: TAuth.TwoFactorCreate) {
    const response = await axiosInstance.post<TAuth.TwoFactorCreate>(getUserUrl('/create-account'), toFormData(data));
    return response;
  },
};

export const ROLE_USER = [
  {
    label: 'Администратор',
    value: 'Admin',
  },
  {
    label: 'Пользователь',
    value: 'User',
  },
  {
    label: 'Агент',
    value: 'Agent',
  },
  {
    label: 'Менеджер',
    value: 'Manager',
  },
  {
    label: 'Приложение',
    value: 'Application',
  },
];

import { FieldValues } from 'react-hook-form';

const isFormChanged = (formFields: FieldValues, savedData?: FieldValues) => {
  const filledFields = Object.keys(formFields).filter((field) => ![undefined, null, ''].includes(formFields[field]));
  if (!savedData) {
    return filledFields.length !== 0;
  }
  for (let i = 0; i < filledFields.length; i++) {
    const fieldName = filledFields[i];
    if (formFields[fieldName] !== savedData[fieldName]) {
      return true;
    }
  }
  return false;
};

export default isFormChanged;

import { GridColDef } from '@mui/x-data-grid';
import { ROWS } from './MOCK_ROW';

export const COLUMNS_USERS: GridColDef<(typeof ROWS)[number]>[] = [
  //TODO добавить удаление
  // {
  //   field: 'selected',
  //   headerName: '',
  // },
  {
    field: 'name',
    headerName: 'Name',
  },
  {
    field: 'email',
    headerName: 'Email',
  },
  {
    field: 'role',
    headerName: 'Role',
  },
  {
    field: 'method_id',
    headerName: 'Method',
  },
  {
    field: 'phone',
    headerName: 'Phone',
  },
  {
    field: 'birthday',
    headerName: 'Birthday',
  },
  {
    field: 'edit',
    headerName: '',
  },
];

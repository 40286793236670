import { Box, FormControl, InputLabel, Select, SelectChangeEvent } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { Control, FieldValues, RegisterOptions } from 'react-hook-form';
import CircularProgressCenter from '../CircularProgressCenter';
import { ClearFormItem } from '../clear-form-button';

interface IProps extends PropsWithChildren {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  labelInner?: string;
  htmlId: string;
  disabled?: boolean;
  onBlur?: any;
  isLoading?: boolean;
  control?: Control<FieldValues>;
  rules?: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  name: string;
}

export const FilterItem: FC<IProps> = ({
  value,
  children,
  setValue,
  labelInner,
  placeholder,
  htmlId,
  onBlur,
  disabled,
  isLoading,
  control,
  rules,
  name,
}) => {
  const handleChange = (event: SelectChangeEvent) => {
    setValue(event.target.value as string);
  };

  return (
    <FormControl fullWidth sx={{ minWidth: '170px' }}>
      <InputLabel shrink sx={{ backgroundColor: '#fff' }} htmlFor='htmlId'>
        {labelInner}
      </InputLabel>
      <Select
        labelId={htmlId}
        key={value}
        name={name}
        id={htmlId}
        onBlur={onBlur}
        disabled={disabled || isLoading}
        value={value}
        label={labelInner}
        style={!value ? { color: '#90939B' } : {}}
        displayEmpty
        renderValue={(selected) => {
          if (selected === '' || undefined === selected) {
            return <>{placeholder}</>;
          } else {
            if (children instanceof Array) {
              return children?.map((item: any) => (item.props.value === value ? item.props.children : ''));
            }
          }
        }}
        onChange={handleChange}
      >
        {children}
      </Select>
      {isLoading && (
        <Box
          sx={{
            position: 'absolute',
            top: '45%',
            right: 30,
            transform: 'translate(0%, -50%)',
            width: 27,
            height: 27,
            cursor: 'pointer',
            minWidth: 27,
            p: 0,
          }}
        >
          <CircularProgressCenter isSelect />
        </Box>
      )}

      {value && !isLoading && !disabled && (
        <ClearFormItem
          rightPercent={5}
          onClick={() => {
            setValue('');
          }}
        />
      )}
    </FormControl>
  );
};

import { Box, Button, Grid, MenuItem } from '@mui/material';
import { FilterContext } from 'app/store/filter-context';
import { useGetAccounts } from 'features/users/api/useGetAccounts';
import { FC, PropsWithChildren, useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import isFormChanged from 'shared/helpers/isFormChanged';
import { TFilters } from 'shared/services/types/common.types';
import { TUsers } from 'shared/services/types/users.types';
import { FilterItem } from 'shared/ui/filter-item';
import { FilterItemDate } from 'shared/ui/filter-item-date';
import { FormWrapper } from 'shared/ui/form-wrapper';
import { ModalTemplate } from 'shared/ui/modal-template';

interface IProps extends PropsWithChildren {
  methodID: number;
  onSubmit: (data: TFilters) => void;
  onClose: () => void;
}

export const FilterModal: FC<IProps> = ({ methodID, onSubmit, onClose, children }) => {
  const { isFilterOpen, setIsFilterOpen } = useContext(FilterContext);
  const methods = useForm({ mode: 'onBlur' });
  const { control, reset } = methods;
  const formErrors = methods.formState.errors;
  const isConfirmationNeeded = isFormChanged(methods.watch());

  const defaultFilterU = {
    size: 1000,
    page: 0,
    order_by: 'method_id',
    method_id: methodID,
    is_desc_sort: true,
  } as TUsers.GetAccount;

  const { isUsersLoading, users } = useGetAccounts(defaultFilterU);

  return (
    <ModalTemplate
      isConfirmationNeeded={isConfirmationNeeded}
      close={() => {
        setIsFilterOpen(false);
      }}
      isOpen={isFilterOpen}
      titleText={'Filter'}
      hideBackdrop={false}
      reset={reset}
    >
      <FormWrapper onSubmit={onSubmit} methods={methods}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Grid container direction={'column'} rowGap={15} wrap={'nowrap'}>
            <Grid item sx={{ minWidth: '19%' }}>
              <Controller
                control={control}
                name='accountNumber'
                render={({ field: { onChange, onBlur, value } }) => (
                  <FilterItem
                    value={value === undefined ? '' : value}
                    setValue={onChange}
                    onBlur={onBlur}
                    labelInner='Account'
                    placeholder='Choose account'
                    htmlId='account-number'
                    name='accountNumber'
                    control={control}
                  >
                    {!isUsersLoading &&
                      users?.items.map((user) => (
                        <MenuItem
                          key={user.account_id.toString()}
                          value={user.account_id.toString()}
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alighItems: 'center',
                          }}
                        >
                          {user.account_id}
                          <span style={{ color: '#44464F', marginLeft: '15px' }}>{user.balance}</span>
                        </MenuItem>
                      ))}
                  </FilterItem>
                )}
              />
            </Grid>
            <Grid item sx={{ minWidth: '19%' }}>
              <Controller
                control={control}
                name='status'
                render={({ field: { onChange, onBlur, value } }) => (
                  <FilterItem
                    labelInner='Status'
                    placeholder='All'
                    value={value === undefined ? '' : value}
                    setValue={onChange}
                    onBlur={onBlur}
                    htmlId='status'
                    name='status'
                    control={control}
                  >
                    <MenuItem value={'received'}>Pending</MenuItem>
                    <MenuItem value={'declined'}>Declined</MenuItem>
                    <MenuItem value={'done'}>Approved</MenuItem>
                  </FilterItem>
                )}
              />
            </Grid>
            <Grid item sx={{ minWidth: '19%' }}>
              <Controller
                control={control}
                name='dateRange'
                render={({ field: { onChange, onBlur, value } }) => (
                  <FilterItemDate
                    value={value === undefined ? [null, null] : value}
                    setValue={onChange}
                    labelText='Date from/to'
                    onBlur={onBlur}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={'30px'} display={'flex'} gap={'16px'} alignItems={'center'} justifyContent={'center'}>
          <Button
            variant='contained'
            fullWidth
            onClick={onClose}
            sx={{
              maxWidth: 175,
              m: 0,
              borderRadius: 50,
              textTransform: 'none',
              background: '#E3E3E4',
              color: '#1D1B20',
              boxShadow: 'none',
              '&:hover': {
                backgroundColor: '#e2e2e2',
              },
              height: 40,
            }}
          >
            Cancel
          </Button>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            sx={{ m: 0, borderRadius: 50, textTransform: 'none', maxWidth: 175, height: 40, boxShadow: 'none' }}
          >
            Save
          </Button>
        </Box>
      </FormWrapper>
    </ModalTemplate>
  );
};
